import styled from "@emotion/styled";
import Colors from "styles/colors";
import { LAYER_ONE, TOP_LAYER, ZERO } from "constants/z-index";
import { BRAND_BLACK_TEXT_COLOR, LIGHT_WORK_OS_IRIS_COLOR_NAME, SPACE_BLUE_COLOR_NAME, UI_BORDER_LIGHT_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import { getComponentShadow } from "segments/desktop/constants";
import { hexToRgba } from "utils/general";
const BORDER_COLOR = hexToRgba(Colors[LIGHT_WORK_OS_IRIS_COLOR_NAME], 0.5);
export const StyledSquareTagComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: ${TOP_LAYER};
  cursor: pointer;

  .background-circle {
    transition: opacity 100ms ease-in-out;
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: currentColor;
    opacity: 0%;
    filter: blur(30px);
    z-index: ${ZERO};
  }

  .star-image {
    .star-picture {
      display: flex;

      .star-icon {
        height: 40px;
      }
    }
  }

  .star-text {
    transition: opacity 100ms ease-in-out;
    text-align: center;
    font-size: 0.875rem;
    color: ${Colors[WHITE_COLOR_NAME]};
    opacity: 85%;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 8px;
  }

  &:active {
    outline: none;
  }

  &.selected,
  &:hover {
    .background-circle {
      opacity: 40%;
    }

    .star-text {
      opacity: 100%;
      z-index: ${LAYER_ONE};
    }
  }

  &.selectable-cluster {
    justify-content: unset;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    margin: 8px;
    padding: 8px 8px 16px 8px;
    height: calc(100% - 16px);
    transition: border 0.1s ease-in-out;

    .checkbox {
      border: 1px solid rgba(255, 255, 255, 0.3);
      transition: all 0.05s ease-in-out;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      align-self: start;

      svg {
        path {
          fill: transparent;
        }
      }
    }

    &.selected,
    &:hover {
      transition: border 0.1s ease-in-out;
      border-color: currentColor;

      .background-circle {
        opacity: 0;
      }

      .checkbox {
        border-color: currentColor;
      }
    }

    &.selected {
      .checkbox {
        background-color: currentColor;

        svg {
          path {
            fill: ${Colors[SPACE_BLUE_COLOR_NAME]};
          }
        }
      }

      .checkbox.custom-check-color {
        svg {
          path {
            fill: currentColor;
          }
        }
      }
    }

    &.cluster-selected {
      animation: breatheIn 0.3s linear;
    }
  }

  &.light-theme::after {
    position: absolute;
    content: "";
    background: white;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    ${getComponentShadow()}
  }

  &.light-theme {
    border-color: transparent;

    &.show-border-in-light-theme {
      &::after {
        box-shadow: none;
      }

      &:not(.selected):not(.hovered) {
        border-color: ${Colors[UI_BORDER_LIGHT_COLOR_NAME]};

        .checkbox {
          border-color: ${Colors[UI_BORDER_LIGHT_COLOR_NAME]};
        }
      }
    }

    // remove buggy drop-shadow filter behavior in safari in hover interaction and use border instead
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        border-color: ${BORDER_COLOR};
        &::after {
          filter: none;
        }
      }
    }

    .checkbox {
      border-color: ${BORDER_COLOR};

      svg {
        path {
          fill: ${Colors[WHITE_COLOR_NAME]};
        }
      }
    }

    .star-text {
      color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
    }

    &.selected {
      .checkbox {
        background-color: currentColor;

        svg {
          path {
            fill: ${Colors[WHITE_COLOR_NAME]};
          }
        }
      }
    }
  }

  @keyframes breatheIn {
    0% {
      transform: scale(1);
    }

    15% {
      transform: scale(0.995);
    }

    20% {
      transform: scale(0.99);
    }

    25% {
      transform: scale(0.985);
    }

    32% {
      transform: scale(0.98);
    }

    50% {
      transform: scale(0.975);
    }

    68% {
      transform: scale(0.98);
    }

    75% {
      transform: scale(0.985);
    }

    80% {
      transform: scale(0.99);
    }

    85% {
      transform: scale(0.995);
    }

    100% {
      transform: scale(1);
    }
  }
`;
